import { Suspense } from "react";
import { Link } from "@remix-run/react";
import { 
    EnhancedMenu, 
    EnhancedMenuItem, 
    useIsHomePath 
} from "~/libs/utils";
import { 
  CountrySelector, 
  Heading, 
  LogoIcon, 
  Section 
} from "~/components";
import { 
  ArrowDownIcon, 
  ArrowUpIcon 
} from "@heroicons/react/20/solid";
import { Disclosure } from "@headlessui/react";
import clsx from "clsx";
import FooterFastlane from "./FooterFastlane";

export function Footer({menu}: {menu?: EnhancedMenu}) {
  const isHome = useIsHomePath();
  const itemsCount = menu
    ? menu?.items?.length + 1 > 4
      ? 4
      : menu?.items?.length + 1
    : [];

  return (
    <footer className="bg-black-off">
      <div className={clsx(
        'relative align-start rounded-b-xl overflow-hidden text-contrast/80 bg-viva-magenta', //
        'px-4 md:px-12 lg:px-20 lg:py-10',
      )}>
        <div className="container grid justify-between items-start gap-x-8 gap-y-12 pt-20 pb-12 grid-cols-2 lg:grid-cols-7">
          <div className="flex flex-col col-span-2 lg:col-span-3">
            {/* <!-- Logo --> */}
            <Link to="/" className="my-2 inline-block">
              <LogoIcon className="max-h-12 text-left" classNameInner="fill-contrast"/>
            </Link>
            <p className="mb-10">
              Accelerate the globalization of innovative brands.
            </p>

            {/* <!-- Socials --> */}
            {/* <div className="flex space-x-5 mt-auto">
              {socialIcons.map((item) => {
                const { id, href, text } = item;
                return (
                  <Link 
                    to={href} 
                    key={id}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="group cursor-pointer"
                  >
                    <svg className="icon h-5 w-5 fill-slate-800 dark:fill-slate-300 group-hover:fill-accent dark:group-hover:fill-white">
                      <use href={`/icons.svg#icon-${text}`}></use>
                    </svg>
                  </Link>
                );
              })}
            </div> */}
            
            <CountrySelector />
          
          </div>
          <FooterMenu menu={menu} />
        </div>
      </div>
      <FooterFastlane className="-my-overlap pt-overlap"/>
    </footer>
  );
}

const FooterLink = ({item}: {item: EnhancedMenuItem}) => {
  if (item.to.startsWith('http')) {
    return (
      <a href={item.to} target={item.target} rel="noopener noreferrer">
        {item.title}
      </a>
    );
  }

  return (
    // <Link to={item.to} target={item.target} prefetch="intent">
    //   {item.title}
    // </Link>
    <Link to={item.to} target={item.target} prefetch='none'>
      {item.title}
    </Link>
  );
};

function FooterMenu({menu}: {menu?: EnhancedMenu}) {
  const styles = {
    section: 'grid gap-4',
    nav: 'grid gap-2 pb-6 hover:text-primary',
  };

  // {footerMenuList.map((single) => (
  //   <div
  //     className={`col-span-full md:col-span-2 ${single.diffClass}`}
  //     key={single.id}
  //   >
  //     <h3 className="font-display text-slate-900 mb-6 text-sm dark:text-white">
  //       {single.title}
  //     </h3>
  //     <ul className="flex flex-col space-y-1 text-sm">
  //       {single.list.map((item) => {
  //         const { id, href, text } = item;
  //         return (
  //           <li key={id}>
  //             <Link href={href} className="hover:text-accent dark:hover:text-white">
  //               {text}
  //             </Link>
  //           </li>
  //         );
  //       })}
  //     </ul>
  //   </div>
  // ))}

  return (
    <>
      {(menu?.items || []).map((item: EnhancedMenuItem) => (
        <section key={item.id} className={styles.section}>
          <Disclosure>
            {({open}) => (
              <>
                <Disclosure.Button className="text-left md:cursor-default">
                  <Heading className="flex justify-between text-contrast/80" size="lead" as="h3">
                    {item.title}
                    {item?.items?.length > 0 && (
                      <span className="md:hidden">
                        {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
                      </span>
                    )}
                  </Heading>
                </Disclosure.Button>
                {item?.items?.length > 0 ? (
                  <div
                    className={`${
                      open ? `max-h-48 h-fit` : `max-h-0 md:max-h-fit`
                    } overflow-hidden transition-all duration-300`}
                  >
                    <Suspense data-comment="This suspense fixes a hydration bug in Disclosure.Panel with static prop">
                      <Disclosure.Panel static>
                        <nav className={styles.nav}>
                          {item.items.map((subItem) => (
                            <FooterLink key={subItem.id} item={subItem} />
                          ))}
                        </nav>
                      </Disclosure.Panel>
                    </Suspense>
                  </div>
                ) : null}
              </>
            )}
          </Disclosure>
        </section>
      ))}
    </>
  );
}
