
import type {
  ButtonHTMLAttributes, 
  ElementType, 
  HTMLAttributeAnchorTarget
} from 'react';
import { Link } from '..';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

type ButtonMode = 'default' | 'outline' | 'brutalist';
type ButtonTone = 'critical' | 'default' | 'accent' | 'light';

type Props = {
  as?: ElementType;
  className?: string;
  mode?: ButtonMode;
  target?: HTMLAttributeAnchorTarget;
  onClick?: () => void;
  to?: string;
  tone?: ButtonTone;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export type ButtonStyleOptions = {
  mode?: ButtonMode;
  tone?: ButtonTone;
};

export const defaultButtonStyles = (options?: ButtonStyleOptions) => {
  const mode: ButtonMode = options?.mode || 'default';
  const tone: ButtonTone = options?.tone || 'default';

  return clsx([
    'flex h-[2.5rem] items-center justify-center overflow-hidden p-4 text-sm font-bold duration-200 ease-out',
    'disabled:opacity-20 disabled:bg-opacity-100',
    mode === 'default' &&
      clsx([
        tone === 'critical' && 'bg-danger',
        tone === 'default' && 'bg-primary',
        tone === 'accent' && 'bg-accent',
        tone === 'light' && 'bg-contrast',
        'hover:opacity-80 text-white',
      ]),
    mode === 'outline' &&
      clsx([
        tone === 'critical' && 'border-color-danger text-danger',
        tone === 'default' && 'border-color-primary text-primary',
        tone === 'accent' && 'border-color-accent text-accent',
        tone === 'light' && 'border-color-contrast text-contrast',
        'bg-transparent border hover:opacity-50',
      ]),
    mode === 'brutalist' &&
      clsx([
        tone === 'critical' && 'bg-danger shadow-mdBrutal',
        tone === 'default' && 'bg-secondary shadow-mdBrutal',
        tone === 'accent' && 'bg-accent shadow-mdBrutal',
        tone === 'light' && 'bg-contrast shadow-mdBrutal',
        'hover:opacity-80 text-primary',
      ]),
  ]);
};

export function Button({
  as = 'button',
  className,
  mode = 'default',
  tone,
  ...props
}: Props) {
  const Component = props?.to ? Link : as;

  return (
    <Component
      className={clsx(defaultButtonStyles({mode, tone}), className)}
      {...props}
    />
  );
}
