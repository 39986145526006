import clsx from "clsx";

export function LogoIcon({
  width,
  height,
  className,
  classNameInner,
}: {
  width?: string;
  height?: string;
  className?: string;
  classNameInner?: string;
}) {
  return (
    <svg 
      className={className} 
      width={width} height={height} viewBox="0 0 1658.00 354.00"
      xmlns="http://www.w3.org/2000/svg" version="1.0" preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.00,354.00) scale(0.10,-0.10)" fill="#000000" stroke="none">
        <path className={classNameInner} fill="#F20000" d="M1135 3529 c-180 -26 -364 -127 -473 -257 -101 -122 -148 -231 -186 -433 -116 -613 -245 -1310 -346 -1869 -66 -366 -122 -675 -125 -687 l-5 -23 349 0 349 0 5 23 c9 35 216 1139 219 1166 3 20 -28 44 -252 190 -140 92 -254 171 -254 177 1 7 585 324 637 346 4 2 30 128 57 280 52 291 64 331 122 388 72 73 39 70 760 70 l647 0 10 53 c6 28 31 165 56 302 25 138 47 258 49 268 4 16 -38 17 -777 16 -430 -1 -809 -5 -842 -10z"/>
        <path className={classNameInner} fill="#F20000" d="M7945 3111 c-6 -5 -182 -122 -393 -260 l-382 -251 -178 0 -178 0 -62 -332 c-34 -183 -62 -341 -62 -350 0 -16 15 -18 165 -18 l165 0 0 -28 c0 -38 -36 -240 -120 -672 -55 -282 -71 -386 -71 -457 -2 -247 110 -382 376 -454 72 -20 111 -23 383 -27 166 -2 302 0 302 4 0 4 25 137 55 295 30 158 55 294 55 303 0 14 -21 16 -164 16 -91 0 -176 4 -190 10 -43 16 -59 63 -53 154 5 80 72 466 124 724 l27 132 228 0 c125 0 228 3 228 7 0 12 120 657 126 676 5 16 -10 17 -220 17 -144 0 -226 4 -226 10 0 5 20 119 45 253 25 134 45 247 45 250 0 10 -14 8 -25 -2z"/>
        <path className={classNameInner} fill="#F20000" d="M5623 2685 c-369 -67 -671 -336 -737 -657 -20 -95 -20 -134 -1 -223 20 -95 56 -165 123 -236 85 -92 157 -138 423 -268 311 -152 359 -191 359 -296 0 -85 -46 -146 -143 -190 -142 -66 -339 -38 -622 86 -93 40 -97 41 -110 23 -7 -11 -79 -107 -159 -214 -80 -107 -146 -198 -146 -203 0 -12 135 -96 213 -132 172 -79 348 -115 562 -115 257 0 476 55 668 168 165 98 266 203 343 357 52 105 74 197 74 315 0 147 -42 252 -140 350 -74 74 -135 113 -371 235 -214 112 -235 125 -318 194 -108 90 -136 172 -87 256 47 79 126 118 241 118 99 -1 206 -34 332 -104 48 -27 92 -48 98 -45 5 2 90 77 189 167 l178 164 -57 52 c-100 91 -272 163 -469 198 -108 18 -341 19 -443 0z"/>
        <path className={classNameInner} fill="#F20000" d="M8677 3503 c-3 -21 -116 -627 -252 -1348 -274 -1451 -272 -1433 -226 -1568 59 -173 192 -273 418 -312 85 -15 523 -22 523 -9 0 3 25 138 55 299 30 162 55 299 55 305 0 6 -35 10 -93 10 -108 0 -150 17 -179 71 -32 60 -24 134 83 719 58 317 329 1769 345 1843 l6 27 -365 0 -364 0 -6 -37z"/>
        <path className={classNameInner} fill="#F20000" d="M13264 2685 c-149 -23 -300 -80 -411 -156 -49 -34 -52 -35 -48 -11 2 9 6 32 9 50 l6 32 -314 0 -314 0 -6 -27 c-3 -16 -100 -532 -216 -1148 -116 -616 -212 -1130 -215 -1143 l-5 -22 314 0 314 0 6 27 c3 16 58 303 121 638 123 652 155 778 225 896 77 130 200 215 354 244 218 42 369 -16 427 -163 30 -76 31 -241 1 -392 -50 -253 -117 -608 -167 -890 -31 -168 -58 -317 -61 -333 l-6 -27 301 0 c186 0 301 4 301 10 0 17 189 1013 225 1184 113 536 117 722 22 915 -61 126 -204 240 -357 285 -144 43 -348 56 -506 31z"/>
        <path className={classNameInner} fill="#F20000" d="M15512 2690 c-439 -55 -863 -335 -1102 -725 -88 -144 -155 -323 -184 -494 -20 -121 -21 -350 -1 -464 82 -469 456 -747 1005 -747 369 0 676 124 960 387 l61 56 -158 159 c-87 87 -161 158 -165 158 -4 0 -43 -26 -86 -57 -156 -116 -321 -173 -503 -173 -215 0 -367 86 -452 255 -24 48 -47 135 -47 175 0 20 15 20 812 22 l813 3 17 80 c55 255 63 545 19 709 -107 403 -402 643 -811 660 -58 3 -138 1 -178 -4z m88 -556 c115 -27 207 -99 261 -205 35 -71 55 -181 42 -235 l-9 -39 -489 4 c-270 2 -491 4 -493 6 -1 1 4 21 12 44 20 56 96 171 153 232 88 95 238 179 353 199 70 12 100 11 170 -6z"/>
        <path className={classNameInner} fill="#F20000" d="M3405 2584 c-520 -79 -997 -489 -1159 -994 -46 -144 -60 -233 -60 -380 0 -428 247 -766 650 -893 257 -81 576 -54 862 75 35 15 65 28 66 28 3 0 -11 -104 -19 -137 l-5 -23 314 0 315 0 10 53 c6 28 103 542 216 1142 113 600 208 1102 211 1118 l6 27 -315 0 -315 0 -12 -63 c-17 -94 -17 -94 -98 -53 -202 102 -432 136 -667 100z m259 -565 c83 -28 141 -64 200 -125 148 -150 179 -394 77 -618 -124 -274 -397 -458 -681 -460 -173 -1 -316 66 -412 191 -69 92 -91 156 -96 289 -5 132 10 202 68 319 157 318 545 504 844 404z"/>
        <path className={classNameInner} fill="#F20000" d="M10670 2594 c-486 -62 -915 -361 -1140 -796 -109 -210 -155 -409 -147 -633 7 -192 52 -344 144 -482 194 -292 549 -453 917 -415 161 17 296 54 505 140 11 4 12 -6 7 -54 -4 -32 -9 -67 -12 -77 -5 -16 13 -17 312 -15 l317 3 218 1160 c120 638 218 1163 219 1168 0 4 -141 7 -314 7 l-314 0 -11 -50 c-6 -28 -11 -56 -11 -63 -1 -34 -16 -38 -61 -13 -66 37 -173 75 -271 97 -86 19 -288 32 -358 23z m207 -582 c82 -31 134 -65 190 -124 255 -270 129 -746 -258 -979 -140 -85 -338 -119 -483 -85 -246 58 -398 266 -383 521 17 299 235 567 539 665 128 41 291 42 395 2z"/>
        <path className={classNameInner} fill="#F20000" d="M1428 2172 l-677 -347 162 -107 c89 -58 338 -219 552 -357 l390 -251 32 163 c39 196 218 1145 228 1205 4 23 3 42 -2 42 -4 0 -313 -157 -685 -348z"/>
      </g>
    </svg>
  
  );
}
