/* eslint-disable hydrogen/prefer-image-component */
import { Fragment, MouseEventHandler, useState } from 'react'
import { useWindowScroll } from 'react-use'
import { clsx } from 'clsx'
import { Link, NavLink, useMatches } from '@remix-run/react'
import { 
  EnhancedMenu, 
  useIsHomePath 
} from '~/libs/utils'
import { 
  Dialog, 
  Disclosure, 
  Popover, 
  Transition 
} from '@headlessui/react'
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
  UserCircleIcon
} from '@heroicons/react/24/outline'
import { 
  ChevronDownIcon, 
  PhoneIcon, 
  PlayCircleIcon,
  RectangleGroupIcon,
  ArrowRightOnRectangleIcon,
} from '@heroicons/react/20/solid'
import {
  LogoIcon,
} from '~/components';

const products = [
  {
    name: 'Analytics',
    description: 'Get a better understanding where your traffic is coming from',
    href: '#',
    icon: ChartPieIcon,
  },
  {
    name: 'Engagement',
    description: 'Speak directly to your customers with our engagement tool',
    href: '#',
    icon: CursorArrowRaysIcon,
  },
  { name: 'Security', description: 'Your customers’ data will be safe and secure', href: '#', icon: FingerPrintIcon },
  {
    name: 'Integrations',
    description: 'Your customers’ data will be safe and secure',
    href: '#',
    icon: SquaresPlusIcon,
  },
]
const callsToAction = [
  { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
  { name: 'Contact sales', href: '#', icon: PhoneIcon },
  { name: 'View all products', href: '#', icon: RectangleGroupIcon },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export function Header({title, menu}: {title: string; menu?: EnhancedMenu}) {
  const isHome = useIsHomePath();

  const {y} = useWindowScroll();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <header
      role="banner"
      className={clsx(
        isHome
          ? 'bg-viva-magenta dark:bg-viva-magenta text-primary dark:text-white'
          : 'bg-accent/80 text-primary',
        // (!isHome && y > 50) &&
        //   'shadow-lightHeader',
        'sticky isolate transition-color duration-300 backdrop-blur-lg top-0 z-40 leading-none'
      )}
    >
      <nav className="mx-auto flex max-w-7xl items-center justify-between px-4 lg:px-6" aria-label="Global">
        <div className="flex lg:flex-1">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Fastlane</span>
            <LogoIcon className="max-h-7 h-auto w-36" classNameInner={isHome ? "fill-white" : "fill-fastlane"} />
          </Link>
        </div>
        <div className="flex lg:hidden py-4">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          <Popover>
            <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 outline-none">
              Services
              <ChevronDownIcon className="h-5 w-5 flex-none" aria-hidden="true" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 -translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 -translate-y-1"
            >
              <Popover.Panel className="absolute inset-x-0 top-0 -z-10 bg-accent pt-16 shadow-lg ring-1 ring-gray-900/10">
                <div className="mx-auto grid max-w-7xl grid-cols-4 gap-x-4 px-6 py-10 lg:px-8 xl:gap-x-8">
                  {products.map((item) => (
                    <div key={item.name} className="group relative transition-color duration-150 rounded-lg p-6 text-sm leading-6 hover:bg-secondary">
                      <div className="flex h-11 w-11 items-center justify-center rounded-lg bg-gray-50 group-hover:bg-contrast">
                        <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                      </div>
                      <a href={item.href} className="mt-6 block font-semibold text-primary">
                        {item.name}
                        <span className="absolute inset-0" />
                      </a>
                      <p className="mt-1 text-primary/80">{item.description}</p>
                    </div>
                  ))}
                </div>
                <div className="bg-gray-50">
                  <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="grid grid-cols-3 divide-x divide-gray-900/5 border-x border-gray-900/5">
                      {callsToAction.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-contrast"
                        >
                          <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
          {(menu?.items || []).map((item) => (
            <NavLink
              key={item.id}
              to={item.to}
              target={item.target}
              prefetch="intent"
              className={({isActive}) =>
                isActive ? 
                  'text-sm font-semibold leading-6 text-black' : 
                  'text-sm font-semibold leading-6'
              }
            >
              {item.title}
            </NavLink>
          ))}
        </Popover.Group>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end lg:items-center lg:justify-self-end self-stretch">
          <AccountLink className="h-full text-sm font-semibold leading-6 px-8 py-6 border-l border-primary hover:bg-accent"/>
          <Link to="/apps" className="h-full text-sm font-semibold leading-6 px-8 py-6 border-x border-primary hover:bg-accent">
            Toolbox <span aria-hidden="true">&rarr;</span>
          </Link>
        </div>
      </nav>
      <hr className="border-primary"/>
      {/* Mobile Menu */}
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-contrast p-5 sm:max-w-sm sm:ring-1 sm:ring-primary/20">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Fastlane</span>
              <img
                className="h-8 w-auto"
                src="/favicon.png"
                alt=""
              />
            </Link>
            <button
              type="button"
              className="rounded-md"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-4 flow-root text-primary/80">
            <div className="divide-y divide-primary/20">
              <div className="space-y-2 py-4">
                <Disclosure as="div" className="-mx-2">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 px-2 text-base font-semibold leading-7 hover:bg-contrast">
                        Product
                        <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-1">
                        {[...products, ...callsToAction].map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-lg py-1 pl-4 pr-2 text-sm font-semibold leading-7 text-primary/60 hover:bg-contrast"
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                {(menu?.items || []).map((item) => (
                  <span key={item.id} className="block -mx-2 rounded-lg py-2 px-2 text-base font-semibold leading-7">
                    <NavLink
                      to={item.to}
                      target={item.target}
                      onClick={() => setMobileMenuOpen(false)}
                      className={({isActive}) =>
                        isActive ? 
                          'text-accent hover:bg-contrast' : 
                          'hover:bg-contrast'
                      }
                    >
                      {item.title}
                    </NavLink>
                  </span>
                ))}
                
              </div>
              <div className="py-4 -mx-2 ">
                <AccountLink 
                  className="block rounded-lg py-2 px-2 text-base font-semibold leading-7 hover:bg-contrast"
                  onClick={() => setMobileMenuOpen(false)}
                />
                <NavLink
                  to="/apps"
                  onClick={() => setMobileMenuOpen(false)}
                  className={({isActive}) =>
                    isActive ? 
                      'block rounded-lg py-2 px-2 text-base font-semibold leading-7 text-accent hover:bg-contrast' : 
                      'block rounded-lg py-2 px-2 text-base font-semibold leading-7 hover:bg-contrast'
                  }
                >
                  Toolbox
                </NavLink>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}

function AccountLink({ className, onClick }: {className?: string, onClick?: MouseEventHandler}) {
  const [root] = useMatches();
  const isLoggedIn = root.data?.isLoggedIn;
  return isLoggedIn ? (
    <Link to="/account" onClick={onClick} className={className}>
      Account
    </Link>
  ) : (
    <Link to="/account/login" className={className}>
      Sign in
    </Link>
  );
}
