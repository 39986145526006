
import { Button } from './elements/Button';
import { PageHeader, Text } from './Text';

export function NotFound({type = 'page'}: {type?: string}) {
  const heading = `We’ve lost this ${type}`;
  const description = `We couldn’t find the ${type} you’re looking for. Try checking the URL or heading back to the home page.`;

  return (
    <main className="mx-auto flex w-full max-w-7xl flex-auto flex-col justify-center px-6 py-24 sm:py-64 lg:px-8">
      <p className="text-base font-semibold leading-8 text-fastlane">404</p>
      <h1 className="mt-4 text-3xl font-bold tracking-tight sm:text-5xl">{heading}</h1>
      <p className="mt-6 text-base leading-7 text-primary/80">{description}</p>
      <div className="mt-10 self-start">
        <Button mode="brutalist" to={'/'}>
          Take me to the home page
        </Button>
      </div>
    </main>
  );
}